<script lang="ts">
import { createEventDispatcher } from 'svelte'
import { getString } from '@modules/utilities'
import type { ButtonAction, Events, ModalDataConfirm, ModalFnParams } from '@components/Modals/types'

const dispatch = createEventDispatcher()

export const events: Events = null
export let data: ModalDataConfirm

const defaultActions: ButtonAction[] = [
    {
        name: 'cancel',
        text: getString('cancel'),
        style: 'outline-primary',
        fn: (modal: ModalFnParams) => modal.close()
    }
]

const { content, actions = defaultActions }  = data

function close() {
    dispatch('closemodal')
}
</script>

{#if content}
    <div class="modal-body">
        <div class="content">
            {@html content}
        </div>
    </div>
{/if}

{#if actions?.length}
    <div class="modal-footer">
        {#each actions as action}
            <a
                href={`#action-${action.name}`}
                class={`btn btn-${action.style || 'primary'}` }
                data-action={action.name}
                on:click|preventDefault={(e) => action.fn({ close }, e)}
            >
                {action.text}
            </a>
        {/each}
    </div>
{/if}
